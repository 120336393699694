import { createWebHistory, createRouter } from 'vue-router'
import { DOCUMENT_TITLE_PREFIX } from '@/libs/constants'

import { stributor } from './stributor'
import { attendendance } from './attendendance'
import { gameway } from './gameway'

import { liveCasino } from './game/liveCasino'
import { slotGame } from './game/slotGame'
import { sports } from './game/sports'
import { esports } from './game/esports'
import { miniGame } from './game/miniGame'
import { virtualGame } from './game/virtualGame'
import { phishingGame } from './game/phishingGame'
import { rotary } from './game/rotary'

import { result } from './result'
import { live } from './live'
import { board } from './board'
import { csCenter } from './csCenter'
// import { money } from './money'
import { partner } from './partner'
import { mypage } from './mypage'
import { gameLink } from './gameLink'
import { warning } from './warning'
import { getLangCookie, tokenCheck } from '@/libs/auth-helper'

const memberRoutes = [
  stributor,
  attendendance,
  gameway,
  liveCasino,
  slotGame,
  sports,
  esports,
  miniGame,
  virtualGame,
  phishingGame,
  rotary,
  result,
  live,
  board,
  csCenter,
  mypage,
  // money,
  gameLink,
  partner
]

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/views'),
    meta: {
      layout: 'default',
      guestOnly: true,
      forAll: true
    }
  },
  warning,
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/member'),
    children: memberRoutes
  },
  {
    name: 'signup',
    path: '/signup',
    component: () => import('@/views/signup'),
    props: true,
    meta: {
      layout: 'default',
      text: '회원가입',
      gnb: false,
      guestOnly: true
    }
  },
  {
    path: '/winner',
    name: 'winner',
    component: () => import('@/views/winner'),
    children: [
      {
        name: 'mypage',
        path: '/mypage',
        component: () => import('@/views/winner/mypage'),
        props: true,
        meta: {
          layout: 'default',
          text: '마이페이지',
          gnb: false
        }
      },
      {
        name: 'point',
        path: '/point',
        component: () => import('@/views/winner/point'),
        props: true,
        meta: {
          layout: 'default',
          text: '포인트',
          gnb: false
        }
      },
      {
        name: 'sports',
        path: '/sports',
        component: () => import('@/views/winner/sports'),
        redirect: { name: 'sportsWelcome' },
        props: true,
        meta: {
          layout: 'default',
          text: '스포츠',
          gnb: false
        },
        children: [
          {
            name: 'sportsWelcome',
            path: '/sports/welcome',
            component: () => import('@/views/winner/sports/welcome'),
            props: true,
            meta: {
              layout: 'default',
              text: '스포츠 웰컴 화면',
              gnb: false
            }
          },
          {
            name: 'sportsViewer',
            path: '/sports/play/:vendor/:gameCode',
            component: () => import('@/views/winner/sports/viewer'),
            props: true,
            meta: {
              layout: 'default',
              text: '스포츠 웰컴 화면',
              gnb: false
            }
          },
          {
            name: 'sportsHistory',
            path: '/sports/history',
            component: () => import('@/views/winner/sports/history'),
            props: true,
            meta: {
              layout: 'default',
              text: '화면구성설명',
              gnb: false
            }
          }
        ]
      },
      {
        name: 'casino',
        path: '/casino',
        component: () => import('@/views/winner/casino'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'slot',
        path: '/slot',
        component: () => import('@/views/winner/slot'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'mini',
        path: '/mini',
        component: () => import('@/views/winner/mini'),
        redirect: { name: 'minigameWelcome' },
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        },
        children: [
          {
            name: 'minigameWelcome',
            path: '/mini/welcome',
            component: () => import('@/views/winner/mini/welcome'),
            meta: {
              gnbSub: false,
              lnb: true
            }
          },
          {
            name: 'minigameViewer',
            path: '/mini/play/:gameCode',
            component: () => import('@/views/winner/mini/viewer'),
            props: true,
            meta: {
              gnbSub: false,
              lnb: true
            }
          }
        ]
      },
      {
        name: 'qnaList',
        path: '/qnaList',
        component: () => import('@/views/winner/cscenter/qna/list'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'qnadetail',
        path: '/qnadetail/:boardIdx',
        component: () => import('@/views/winner/cscenter/qna/detail'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'qnawrite',
        path: '/qnawrite',
        component: () => import('@/views/winner/cscenter/qna/write'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'noticelist',
        path: '/noticelist',
        component: () => import('@/views/winner/cscenter/notice/list'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'noticedetail',
        path: '/noticedetail/:boardIdx',
        component: () => import('@/views/winner/cscenter/notice/detail'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'eventlist',
        path: '/eventlist',
        component: () => import('@/views/winner/cscenter/event/list'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'eventdetail',
        path: '/eventdetail/:boardIdx',
        component: () => import('@/views/winner/cscenter/event/detail'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'betlist',
        path: '/betlist',
        component: () => import('@/views/winner/bet/list'),
        props: true,
        redirect: { name: 'sportsBetHistory' },
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        },
        children: [
          {
            name: 'casinoBetHistory',
            path: '/betlist/casino',
            component: () => import('@/views/winner/bet/list/casino'),
            meta: {
              groupCode: 'casino',
              gnbSub: false,
              lnb: true
            }
          },
          {
            name: 'slotBetHistory',
            path: '/betlist/slot',
            component: () => import('@/views/winner/bet/list/slot'),
            meta: {
              groupCode: 'slot',
              gnbSub: false,
              lnb: true
            }
          },
          {
            name: 'sportsBetHistory',
            path: '/betlist/sports',
            component: () => import('@/views/winner/bet/list/sports'),
            meta: {
              groupCode: 'sports',
              gnbSub: false,
              lnb: true
            }
          },
          {
            name: 'miniBetHistory',
            path: '/betlist/minigame',
            component: () => import('@/views/winner/bet/list/minigame'),
            meta: {
              groupCode: 'minigame',
              gnbSub: false,
              lnb: true
            }
          }
        ]
      },
      {
        name: 'messagelist',
        path: '/messagelist',
        component: () => import('@/views/winner/message/list'),
        props: true,
        meta: {
          layout: 'default',
          text: '화면구성설명',
          gnb: false
        }
      },
      {
        name: 'cash',
        path: '/cash',
        component: () => import('@/views/winner/cash'),
        props: true,
        children: [
          {
            name: 'cashdeposit',
            path: '/cash/deposit',
            component: () => import('@/views/winner/cash/deposit'),
            props: true,
            meta: {
              layout: 'default',
              text: '화면구성설명',
              gnb: false
            }
          },
          {
            name: 'cashwithdraw',
            path: '/cash/withdraw',
            component: () => import('@/views/winner/cash/withdraw'),
            props: true,
            meta: {
              layout: 'default',
              text: '화면구성설명',
              gnb: false
            }
          }
          // {
          //   name: 'cashexchange',
          //   path: '/cash/exchange',
          //   component: () => import('@/views/winner/cash/exchange'),
          //   props: true,
          //   meta: {
          //     layout: 'default',
          //     text: '화면구성설명',
          //     gnb: false
          //   }
          // }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(), // 2.x대를 설치하시면 작동을 안합니다.
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ top: 0, left: 0 })
      }, 0)
    })
  }
})

router.beforeEach(async(to, from, next) => {
  const title = DOCUMENT_TITLE_PREFIX

  const result = await tokenCheck()
  next()
  if (to.meta.guestOnly || result) {
    next()
  } else {
    const lang = getLangCookie()
    let msg = '로그인 이후 이용 가능합니다.'
    if (lang !== 'ko') {
      msg = 'Please Log-in'
    }
    alert(msg)
    location.href = '/'
  }

  document.title = title
})

export default router
