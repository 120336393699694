export const sports = {
  name: 'sports',
  path: '/sports',
  component: () => import('@/views_mobile/member/sports'),
  redirect: { name: 'sportsWelcome' },
  meta: {
    layout: 'mobile-default',
    text: '스포츠',
    gnb: false
  },
  children: [
    {
      name: 'sportsWelcome',
      path: '/sports/welcome',
      component: () => import('@/views_mobile/member/sports/welcome'),
      props: true,
      meta: {
        layout: 'mobile-default',
        text: '스포츠 웰컴 화면',
        gnb: false
      }
    },
    {
      name: 'sportsHistory',
      path: '/sports/history',
      component: () => import('@/views_mobile/member/sports/history'),
      props: true,
      meta: {
        layout: 'mobile-default',
        text: '화면구성설명',
        gnb: false
      }
    },
    {
      name: 'sportsViews',
      path: '/sports/views',
      component: () => import('@/views_mobile/member/sports/views'),
      redirect: { name: 'sportsWelcome' },
      props: true,
      meta: {
        layout: 'mobile-default',
        text: '스포츠 게임화면',
        gnb: false
      },
      children: [
        {
          name: 'sportsInternal',
          path: '/sports/internal',
          component: () => import('@/views_mobile/member/sports/views/internal'),
          props: true,
          meta: {
            text: '국내스포츠',
            gnb: false
          }
        },
        {
          name: 'sportsOverseas',
          path: '/sports/overseas',
          component: () => import('@/views_mobile/member/sports/views/overseas'),
          props: true,
          meta: {
            text: '해외스포츠',
            gnb: false
          }
        },
        {
          name: 'sportsInplay',
          path: '/sports/inplay',
          component: () => import('@/views_mobile/member/sports/views/inplay'),
          props: true,
          meta: {
            text: '인플레이',
            gnb: false
          }
        },
        {
          name: 'sportsBti',
          path: '/sports/bti',
          component: () => import('@/views_mobile/member/sports/views/bti'),
          props: true,
          meta: {
            text: 'BTI',
            gnb: false
          }
        }
      ]
    }
  ]
}
