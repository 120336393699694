<template>
  <div id="left_section" class="big" v-if="navToggle">
    <button class="btn-nav-close" @click="setNavToggle" v-if="isMobile">
    </button>
    <div class="logo-wrap">
      <router-link to="/">
        <img src="@/assets/img/core/logo.png" />
      </router-link>
    </div>
    <div class="nav-header">
      <div class="admin_info">
        <span>{{ $t('partner.common.userId') }}</span>
        <span>{{ userData.memId }}</span>
      </div>
      <div class="reserves">
        <div class="holding-money">
          <span>{{ $t('partner.common.cashAmt') }}</span>
          <span>{{ thousand(userData.cashAmt) }}</span>
        </div>
        <div class="holding-point">
          <span>{{ $t('partner.common.pointAmt') }}</span>
          <span>{{ thousand(userData.pointAmt) }} P</span>
        </div>
      </div>
    </div>
    <div class="nav-body">
      <ul>
        <li>
          <div class="menu-title">
            <router-link class="menu-contents-item-link" to="/">{{ $t('partner.common.homeRedirect') }}</router-link>
          </div>
        </li>
        <li>
          <div class="menu-title">{{ $t('partner.menu.mypage.title') }}</div><!-- 총판관리 -->
          <ul class="menu-contents">
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_mypage_rateInfo'}">{{ $t('partner.menu.mypage.rateInfo.title') }}</router-link><!-- 하위총판목록 -->
            </li>
          </ul>
        </li>
        <li>
          <div class="menu-title">{{ $t('partner.menu.lower.title') }}</div>
          <ul class="menu-contents">
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_lower_list'}">{{ $t('partner.menu.lower.list.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_lower_create'}">{{ $t('partner.menu.lower.create.title') }}</router-link><!-- 하위총판목록 -->
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_status_list'}">{{ $t('partner.menu.lower.statusList.title') }}</router-link><!-- 하위총판목록 -->
            </li>
          </ul>
        </li>
        <li>
          <div class="menu-title">{{ $t('partner.menu.bet.title') }}</div>
          <ul class="menu-contents">
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_bet_casino'}">{{ $t('partner.menu.bet.casino.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_bet_slot'}">{{ $t('partner.menu.bet.slot.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_bet_sports'}">{{ $t('partner.menu.bet.sports.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_bet_minigame'}">{{ $t('partner.menu.bet.minigame.title') }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <div class="menu-title">{{ $t('partner.menu.reports.title') }}</div>
          <ul class="menu-contents">
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_reports_cash'}">{{ $t('partner.menu.reports.cash.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_reports_point'}">{{ $t('partner.menu.reports.point.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_reports_summary'}">{{ $t('partner.menu.reports.summary.title') }}</router-link>
            </li>
            <li class="menu-contents-item">
              <router-link class="menu-contents-item-link" :to="{name:'partner_reports_daily'}">{{ $t('partner.menu.reports.daily.title') }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <div align="center">
      <a href=""><img src="@/assets/img/winner_pc/go_mobile.png?v=1"></a>
    </div> -->

  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import { isMobile } from '@/libs/utils'
export default {
  name: 'Navigation',
  data() {
    return {
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'navToggle'
    ])
  },
  methods: {
    setNavToggle() {
      store.commit('setNavToggle', false)
    }
  },
  watch: {
    $route(to, from) {
      if (isMobile()) {
        store.commit('setNavToggle', false)
      }
    }
  }
}
</script>
<style scoped lang="scss">
#left_section.big li a {
  text-align: left;
  gap: 20px;
  display: flex;
  align-items: center;
}
#left_section li a {
  display: block;
  height: 39px;
  text-align: left;
  background: #fff;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  cursor: pointer;
  border-radius: 24px;
}
</style>
<style>
.logo-wrap img{
  width: 100%;
}
</style>
