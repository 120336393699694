<template>
  <div class="loginmodal">
    <div class="msgArt">
      <img src="@/assets/img/icon_airplane.svg" />
      <p>{{$t('front.message.new')}}</p>
      <a @click="goPageByName('messagelist')">{{ $t('front.board.check') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewMessage'
}
</script>

<style scoped>
.msgArt p {
  white-space: pre;
}
</style>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
