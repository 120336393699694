import httpClient from '@/libs/http-client'

// 테스트용 링크
export function getGameOld(params) {
  const url = '/api/minigame/getGameOld'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGame(params) {
  const url = '/api/minigame/getGame'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameTriple(params) {
  const url = '/api/minigame/getGameTriple'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getGameSlotList(params) {
  const url = '/api/minigame/getSlotList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getSlotGame(params) {
  const url = '/api/minigame/slots/' + params.prodId + '/' + params.type
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function powerballBetting(params) {
  const url = '/api/bet/betProc'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function powerballResult(params) {
  const url = '/api/minigame/powerballResult'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export const getGameUrlSports = (params) => {
  const url = '/api/game/getGame/crownSports'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const getGameUrlSlot = (params) => {
  const url = '/api/game/getGame/slot'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const getGameUrlCasino = (params) => {
  const url = '/api/game/getGame/casino'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const getGameList = (params) => {
  const url = '/api/game/gamelist'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export const getMiniGameList = () => {
  const url = '/api/minigame/gamelist'
  return httpClient.get(url).then(response => {
    return response
  })
}

export const getMiniGameUrl = (gameName, params) => {
  const url = `/api/minigame/getGame/${gameName}`
  return httpClient.post(url, params).then(response => {
    return response
  })
}

// sports
export const reqTodayEventList = (params) => {
  const url = '/sports/api77/list'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const reqTodayEventListAll = () => {
  const url = '/sports/api77/multiListAll'
  return httpClient.post(url).then(response => {
    return response
  })
}
export const reqTodayEventListSpecial = () => {
  const url = '/sports/api77/listSpecial'
  return httpClient.post(url).then(response => {
    return response
  })
}
export const reqEventListA = () => {
  const url = '/sports/api77/singleListAll'
  return httpClient.post(url).then(response => {
    return response
  })
}
export const reqEventDetail = (params) => {
  const url = '/sports/api77/detail'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const reqSportsBetting = (params) => {
  const url = '/sports/api77/bet'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export const reqSportsCombine = (params) => {
  const url = '/api/setting/sports/combineList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const reqSportsEvent = (params) => {
  const url = '/api/setting/sports/eventList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const reqSportsRate = (params) => {
  const url = '/api/setting/sports/rateList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export const reqSportsSetting = (params) => {
  const url = '/api/setting/game/settingList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
// export const reqSportsSetting2 = (params) => {
//   const url = '/api/setting/game/sportsSetting'
//   return httpClient.post(url, params).then(response => {
//     return response
//   })
// }

export const reqSportsBetCancel = (params) => {
  const url = '/api/bet/sports/cancel'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
