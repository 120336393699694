<template>
  <!-- <div class="loginmodal">
    <div class="modal-content">
      <h1 class="login-popup-title"><img src="@/assets/img/ace_logo.svg" /></h1>
      <h2>{{ $t('front.login.greeting') }}</h2>
      <ui-input :className="'logininput'" :type="'text'" :placeholder="$t('front.common.memId')" @input="getMemId" />
      <ui-input :className="'logininput'" :type="'password'" :placeholder="$t('front.common.password')" @input="getMemPass" @keyup.enter="onSubmit" />

      <section class="captcha-box">
        <VueClientRecaptcha :value="inputValue" :count="4" chars="12345" :hideLines="true" custom-text-color="black" @getCode="getCaptchaCode" @isValid="checkValidCaptcha">
          <template>
            <span style="color: blue">with Custom Text Or Icon</span>
          </template>
        </VueClientRecaptcha>
        <input v-model="inputValue" placeholder="자동입력방지코드" class="input" type="text" @keyup.enter="onSubmit" />
      </section>
      <ui-button :className="'loginnbtn on'" :text="$t('front.login.loginButton')" @click.prevent="onSubmit" />
      <p class="jointxt">{{ $t('front.login.notFoundId') }}<ui-button :className="'joinnbtn'" :text="$t('front.common.signup')" @click="$emit('close', 'join')" /></p>
      <div class="loginclose" @click="$emit('close')"><img src="@/assets/img/icon_cancel.png" /></div>
    </div>
  </div> -->
  <!-- 로그인 팝업 -->
  <div class="popup_mask" @click="loginClosePopupMask">
    <div id="login_popup" class="popup_area">
      <button class="login_popup_close-btn" type="button" @click="closeLogin">
        <i class="fas fa-times"></i>
      </button>
      <div class="popup_inner">
        <div class="popup_box">
          <div class="popup_logo">
            <img src='@/assets/img/core/logo.png' />
          </div>
          <div class="popup_input">
            <p><i><img src="@/assets/img/winner_pc/main/icon_id.png" alt="" /></i><input type="text" id="mb_id" name="mb_id" :placeholder="$t('front.common.memId')" v-model="model.memId" @keyup.enter="onSubmit" @keyup.f2="devToggle" /></p>
            <p><i><img src="@/assets/img/winner_pc/main/icon_password.png" alt="" /></i><input type="password" id="mb_password" name="mb_password" :placeholder="$t('front.common.password')" v-model="model.memPass" @keyup.enter="onSubmit" /></p>
            <p class="save_id"><input type="checkbox" id="save_id" v-model="saveId"><label for="save_id">{{ $t('front.login.saveID') }}</label></p>
            <div class="d-f_1">
              <button type="button" @click.prevent="onSubmit">{{ $t('front.login.loginButton') }}</button>
              <button type="button" @click="$emit('close', 'join')">{{ $t('front.common.signup') }}</button>
            </div>
          </div>
          <!-- <p class="tx">아직도 계정이 없으신가요
          <RouterLink to="signup">회원가입</RouterLink>
        </p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signIn } from '@/api/member'
import { setAuthData, setLoginId, getLoginId, removeLoginId } from '@/libs/auth-helper'
import store from '@/store'

// import VueClientRecaptcha from 'vue-client-recaptcha'

export default {
  name: 'Signin',
  components: {
    // VueClientRecaptcha
  },
  computed: {
    canSubmit() {
      return this.model.memId && this.model.memPass
    },
    loginId() {
      return getLoginId();
    }
  },
  watch: {
    loginId: {
      handler(loginId) {
        if (loginId) {
          this.saveId = true
          this.model.memId = loginId
        } else this.saveId = false
      },
      immediate: true
    }
  },
  data() {
    return {
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      saveId: false,
      inputValue: null
    }
  },
  methods: {
    loginClosePopupMask(e) {
      const target = e.target
      const filter = target.classList.contains('popup_mask')
      if (filter) {
        this.closeLogin()
      }
    },
    closeLogin() {
      this.$emit('close', 'signin')
    },
    onSubmit() {
      if (this.model.memId === '') {
        this.onAlert('warningart', 'front.signin.emptyMemId')
        return
      }
      if (this.model.memPass === '') {
        this.onAlert('warningart', 'front.signin.emptyMemPass')
        return
      }
      if (!this.canSubmit) {
        return
      }
      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data

        if (result.resultCode === '200') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            if (data.msgInfo) {
              store.dispatch('storeUserUnReadMessageCount', data.msgInfo.newMsg)
            }
            if(this.saveId) {
              setLoginId(this.model.memId)
            }else {
              removeLoginId()
            }
            this.closeLogin()
          })
        } else {
          await this.onCheck(this.$t(`api.${result.resultCode}`))
        }
      }).catch(async err => {
        console.error('login err : ', err.message)
        console.log(err)
        if (err.message === 'no member') {
          await this.onCheck('api.U003')
        } else {
          alert(err.message)
        }
      })
    }
  }
}
</script>
<style>
.login_popup_close-btn {
  position: absolute;
  font-size: 2rem;
  top:0;
  right:0;
  padding:20px 30px;
  z-index:10;
}
</style>
