import axios from 'axios'
import { getAuthToken, getRunMode } from '@/libs/auth-helper'
// import { decrypt, encrypt } from '@/libs/crypto'

const baseUrl = process.env.VUE_APP_API_BASE_URL

function isApiUrl(url) {
  if (url.charAt(0) === '/') {
    return true
  }
  return url.indexOf(baseUrl) === 0
}

const httpClient = axios.create({
  baseURL: baseUrl
})

httpClient.interceptors.request.use(function(config) {
  if (isApiUrl(config.url)) {
    // if (config.method === 'get') {
    //   if (!config.params) {
    //     config.params = {}
    //   }
    //   config.params.t = new Date().getTime()
    // }
    const runMode = JSON.parse(getRunMode('run_mode'));
    // console.log(config.url.indexOf('sports') > -1)
    const siteId = runMode.isDev ? runMode.siteId : process.env.VUE_APP_SITE_ID
    config.data = { ...config.data, siteId: siteId }
    const { startDate, endDate } = config.data
    const literal = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    const dateRegex = new RegExp(literal, 'i');
    if(startDate) {
      if(dateRegex.test(startDate)) {
        config.data.startDate = `${startDate} 00:00:00`
      }
    }
    if(endDate) {
      if(dateRegex.test(endDate)) {
        config.data.endDate = `${endDate} 23:59:59`
      }
    }
    // console.log(config.data)
    // config.body = config.data
    if (window.siteLogDataView) {
      console.log(config.url + ' REQ : ', config.data)
    }
    // config.data = encrypt(config.data)
    config.headers = Object.assign(config.headers, {
      // Authorization: 'ite1234567890abcdef'
      // Authorization: process.env.VUE_APP_API_KEY || 'ite1234567890abcdef',
      Authorization: runMode.isDev ? runMode.apiKey : process.env.VUE_APP_API_KEY,
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: '*/*'
    })

    const authToken = getAuthToken()

    if (authToken) {
      config.headers = Object.assign(config.headers, {
        token: authToken
      })
    }
  }
  return config
}, function(error) {
  return Promise.reject(error)
})

httpClient.interceptors.response.use(function(response) {
  // response.data = decrypt(response.data)
  // window.siteLogDataView = true
  if (window.siteLogDataView) {
    console.log(response.config.url + ' RES : ', response.data)
  }

  if (!isApiUrl(response.config.url) && !response.data.resultCode !== '200') {
    const message = response.data.resultMessage || 'RESPONSE_FAILED'
    const error = new Error(message)
    error.isFailed = true
    return Promise.reject(error)
  } else {
    response.headers['Content-Type'] = 'application/json'
    return response
  }
}, function(error) {
  // let needAlert = true
  console.log('http-error: ', error)
  if (window.__VUE_ROOT__) {
    /// window.__VUE_ROOT__.$children[0].signOut()
  } else {
    // removeAuthData()
  }
  // router.push({ name: 'main' })

  // if (error.config.headers[HTTP_HEADER_SKIP_ERROR]) {
  //   needAlert = false
  // } else if (error.response && error.response.status) {
  //   const statusCode = error.response.status
  //
  //   const authFailedStatusCodes = [
  //     HTTP_STATUS.unauthrized,
  //     HTTP_STATUS.unprocessableEntity
  //   ]
  //   if (authFailedStatusCodes.includes(statusCode)) {
  //     needAlert = false
  //     if (window.__VUE_ROOT__) {
  //       window.__VUE_ROOT__.$children[0].signOut()
  //     } else {
  //       removeAuthData()
  //     }
  //     router.push({ name: 'main' })
  //   } else if (statusCode === HTTP_STATUS.badRequest) {
  //     // to-do: what happened?
  //   }
  // }

  // if (needAlert) {
  //   error.isHandled = true
  //   store.commit('setErrorData', error)
  // }
  return Promise.reject(error)
})

export default httpClient
